import { render, staticRenderFns } from "./CustomFlagIn.vue?vue&type=template&id=413bd7a0&scoped=true&"
import script from "./CustomFlagIn.vue?vue&type=script&lang=js&"
export * from "./CustomFlagIn.vue?vue&type=script&lang=js&"
import style0 from "./CustomFlagIn.vue?vue&type=style&index=0&id=413bd7a0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "413bd7a0",
  null
  
)

export default component.exports