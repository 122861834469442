
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      v1Distance: -150,
      v2TitleDistance: 50,
      v3Distance: 300
    };
  },
  mounted() {
    this.v1Distance = window.innerWidth < 1024 ? -40 : -150;
    this.v2TitleDistance = window.innerWidth < 1024 ? 0 : 50;
    this.v3Distance = window.innerWidth < 1024 ? 100 : 300;

    window.addEventListener('resize', () => {
      this.v1Distance = window.innerWidth < 1024 ? -20 : -150;
      this.v2TitleDistance = window.innerWidth < 1024 ? 0 : 50;
      this.v3Distance = window.innerWidth < 1024 ? 100 : 300;
    });
  }
};
